import React from 'react'
import { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.ico'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import config from '../utils/siteConfig'
import Navigation from '../components/Menu'
import Footer from '../components/Footer'

const Template = ({children}) => {
  return (
    <div className="siteRoot" id="siteWrapper">
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Maurice" />

        <link rel="icon" href={favicon} />
      </Helmet>

      <ThemeProvider theme={theme}>
          <div className="siteContent">
            <Navigation pageWrapId={"siteContentWrapper"} outerContainerId={"siteWrapper"}/>
            <div id="siteContentWrapper">
              {children}
            </div>
          <Footer />
          </div>
      </ThemeProvider>
      <GlobalStyle />
    </div>
  )
}

export default Template
