import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    border-top: 1px solid ${props => props.theme.colors.secondary};
  }
  padding: 1em 0 2em;
  margin: 0 1.5em;
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    transition: all 0.2s;
    text-decoration: none;
    color: #aaa;
    vertical-align: middle;
    font-size: 14px;
    display: block;
    text-align: center;
    &:hover {
      color: #222;
    }
    &:visited {
      color: #aaa;
    }
  }
`

const Footer = () => (
  <Wrapper>
    <List>
      <Item>
        <a href="/contact" title="Maurice van der Meijs">
          &copy; Maurice van der Meijs
        </a>
        <a
          href="https://assets.ctfassets.net/do046p6f7p3x/3vM7BpHQQ5l65B3FkU1iGS/330aa439596d2b4277d9c639522e003a/DuPho_Algemene_voorwaarden_augustus_2016.pdf"
          title="Algemene Voorwaarden"
          target="_blank"
        >
          Algemene Voorwaarden
        </a>
      </Item>
    </List>
  </Wrapper>
)

export default Footer
