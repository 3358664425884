import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Menu from 'react-burger-menu/lib/menus/push'
import Headroom from 'react-headroom'


const Wrapper = styled.div`
  max-width: 1400px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    padding-left: 40px;
    padding-right: 40px;
  }
`

const MenuWrapper = styled.div`
  &.light{
    #maurice-van-der-meijs-photography-logo-dark, #closeproject, #Desktop---Project{
      fill: white;
      color: white;
    }
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after, .is-active .hamburger-inner, .is-active .hamburger-inner::before, .is-active .hamburger-inner::after{
      background-color: white;
    }
  }
  &.light, &.dark{
    @media screen and (max-width: ${props => props.theme.responsive.medium}) {
      ${Wrapper}{
        justify-content: flex-end;
      }
    }
  }
`

const HeaderBar = styled(Headroom)`
  position: absolute;
  width: 100%;
  animation-duration: 1s;
  animation-delay: 0s;
  z-index: 999;
  .headroom {
    width: 100%;
    left: 0;
    right: 0;
    z-index: 2;
    top: 25px;
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      top: 50px;
    }
  }
  .headroom--unfixed {
    position: fixed;
    transform: translateY(0);
    transition: transform 300ms ease-in-out;
  }
  .headroom--scrolled {
    transition: transform 300ms ease-in-out;
    &.headroom--pinned{
      transform: translateY(-75px);
      @media screen and (min-width: ${props => props.theme.responsive.medium}) {
        transform: translateY(-175%);
      }
    }
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-75px);
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      transform: translateY(-175%);
    }
  }
  .headroom--pinned {
    position: fixed;
    transition: transform 100ms ease-in-out;
  }

  #maurice-van-der-meijs-photography-logo-dark, #closeproject, #Desktop---Project{
    fill: black;
    color: black;
  }
`

const LogoLink = styled(Link)`
  display: inline-block;
  cursor: pointer;
  margin: 0;
  padding: 0;
  opacity: 1;
  transition: opacity .25s ease-in-out;
  &.hide{
    display: none;
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      display: inline-block;
    }
  }
  &.menu-active{
    opacity: 0;
  }
`

const StyledBurgerMenu = styled.div`
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #222;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #fff;
    padding: 1.5em 1.5em 0;
    font-size: 1.15em;
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      padding: 2.5em 1.5em 0;
    }

  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list a {
    color: #222;
    padding: 0.8em;
    text-decoration: none;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    padding-bottom: 1em;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  
`
const CloseProject = styled(Link)`
  display: none;
  padding: 0 0 40px 40px;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    padding: 15px 0;
  }
  cursor: pointer;
  text-decoration: none;
  color: black;
  font-weight: 300;

  &.show {
    display: inline-block;
  }
  span{
    display: none;
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      display: inline;
    }
  }
`

const Close = ({ className, fill }) => (
  <svg className={className} width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Maurice van de Meijs - Close Button</title>
      <g id="Desktop-view" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Desktop---Project" transform="translate(-1214.000000, -81.000000)">
              <g id="Group-7" transform="translate(1214.000000, 75.000000)">
                  <g id="Group-5" transform="translate(0.000000, 6.000000)">
                      <rect id="Rectangle" transform="translate(7.651189, 7.467859) rotate(-45.000000) translate(-7.651189, -7.467859) " x="6.0148252" y="-1.03214122" width="3.27272727" height="17"></rect>
                      <polygon id="Rectangle" transform="translate(7.703227, 7.584179) rotate(-315.000000) translate(-7.703227, -7.584179) " points="6.08417938 -1.00672971 9.32227462 -1.00672971 9.32227462 16.1750885 6.08417938 16.1750885"></polygon>
                  </g>
              </g>
          </g>
      </g>
  </svg>
);

const StyledClose = styled(Close)`
  margin: 0 3px 0 0;
  width: 15px;
  display: inline;
`

const Hamburger = styled.div`
  padding: 15px 0 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  align-self: center;
  z-index: 1001;
  transition: all 0.5s ease 0s;
  &.is-active {
    transform: translate3d(-300px, 0px, 0px);
  }

  &.hidden {
    display: none;
  }

  &:hover {
    opacity: 0.7;
  }
  &.is-active:hover {
    opacity: 0.7;
  }
  &.is-active .hamburger-inner,
  &.is-active .hamburger-inner::before,
  &.is-active .hamburger-inner::after {
    background-color: ${props => props.fill};
  }

  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: ${props => props.fill};
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: '';
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }
  &.hamburger--slider .hamburger-inner {
    top: 2px;
  }
  &.hamburger--slider .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s;
  }
  &.hamburger--slider .hamburger-inner::after {
    top: 20px;
  }
  &.hamburger--slider.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(45deg);
  }
  &.hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0;
  }
  &.hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-90deg);
  }
`

const Logo = ({ className, fill }) => (
  <svg className={className} width="102px" height="102px" viewBox="0 0 102 102" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <title>Maurice van de Meijs - Logo</title>
  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="maurice-van-der-meijs-photography-logo-dark">
          <polygon id="Path" points="78.1 74.28 69.94 20.56 51.08 59.09 49.53 59.09 30.52 20.26 22.85 74.27 21.15 74.03 29.21 17.25 30.83 17 50.3 56.76 69.61 17.34 71.22 17.59 79.8 74.02"></polygon>
          <path d="M55.24,2.41 C78.2284431,4.58837594 96.5151075,22.6326668 99,45.59 L101.42,45.59 C98.9270335,21.2969633 79.5630823,2.1804078 55.24,7.10542736e-15 L55.24,2.41 Z" id="Path"></path>
          <path d="M2.42,45.6 C4.85316234,23.1004846 22.4823426,5.25161311 44.95,2.54 L44.95,0.12 C21.1430886,2.83806412 2.43882775,21.7628646 0,45.6 L2.42,45.6 Z" id="Path"></path>
          <path d="M44.95,98.95 C22.4823426,96.2383869 4.85316234,78.3895154 2.42,55.89 L0,55.89 C2.43882775,79.7271354 21.1430886,98.6519359 44.95,101.37 L44.95,98.95 Z" id="Path"></path>
          <path d="M99,55.89 C96.5151075,78.8473332 78.2284431,96.8916241 55.24,99.07 L55.24,101.48 C79.5508879,99.2807067 98.8946715,80.1633133 101.38,55.88 L99,55.89 Z" id="Path"></path>
          <polygon id="Path" points="69.89 83.13 65.33 51.2 51 80.5 49.46 80.5 34.87 51.06 30.43 83.13 28.73 82.89 33.55 48.07 35.17 47.8 50.23 78.18 65.01 47.95 66.63 48.2 71.59 82.89"></polygon>
      </g>
  </g>
</svg>
);

const StyledLogo = styled(Logo)`
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  margin: 0;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex: 0 0 75px;
    width: 75px;
    height: 75px;
  }
`

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
      dark: true
    }
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen })
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({ menuOpen: false })
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  fillColor = () => {
    return 'black'
    // let activeSlide = document.getElementsByClassName("slick-active");
    // if(this.state.dark === true){
    //   return 'black'
    // } else{
    //   return 'white'
    // }
  }
  
  render() {
    return (
      <MenuWrapper id="menu">
        <StyledBurgerMenu>
          <Menu
            right
            pageWrapId={this.props.pageWrapId}
            outerContainerId={this.props.outerContainerId}
            isOpen={this.state.menuOpen}
            onStateChange={state => this.handleStateChange(state)}
            customBurgerIcon={false}
            customCrossIcon={false}
          >
            <Link to="/" alt="Home">
              Home.
            </Link>
            <Link to="/work" alt="Work">
              Work.
            </Link>
            <Link to="/about" alt="About">
              About.
            </Link>
            <Link to="/contact" alt="Contact">
              Contact.
            </Link>
          </Menu>
        </StyledBurgerMenu>
        <HeaderBar
          disableInlineStyles
          // className="animated fadeIn"
        >
          <Wrapper>
            <LogoLink to="/" id="logo" className={
                this.state.menuOpen
                  ? 'menu-active'
                  : ''
              }>
              <StyledLogo fill={this.fillColor()} />      
            </LogoLink>
            <Hamburger
              id="hamburger"
              fill={this.fillColor()}
              onClick={() => this.toggleMenu()}
              className={
                this.state.menuOpen
                  ? 'hamburger hamburger--slider is-active'
                  : 'hamburger hamburger--slider'
              }
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </Hamburger>
            <CloseProject to="/" id="closeproject" fill={this.fillColor()}>
              <StyledClose fill={this.fillColor()} /><span> Close</span> 
            </CloseProject>
          </Wrapper>
        </HeaderBar>
      </MenuWrapper>
    )
  }
}

export default Navigation
